import React from "react";
import styled from "styled-components";
import NetlifyForm from 'react-netlify-form'
import * as PropTypes from "prop-types"
import 'normalize.css'



const contact = ({ formName, buttonName }) => {
    return (
        <NetlifyForm 
        name={formName}
        honeypotName='bot-field'
        
        >
            {({ loading, error, success}) => (
                <div>
                    {loading &&
                        <div>Loading...</div>
                    }
                    {error &&
                        <div>Your information was not sent. Please try again later.</div>
                    }
                    {success &&
                        <div>Thank you for contacting us!</div>
                    }
                    {!loading && !success &&
                        <Form>
                            <InputGroup>
                                <input type='text' name='first-ame' placeholder="First Name" required />
                                <input type='text' name='last-name' placeholder="Last Name" required />
                            </InputGroup>
                            <InputGroup>
                                <input type='email' name='email' placeholder="Email Address" required />
                                <input type='phone' name='phone' placeholder="Phone Number" required />
                            </InputGroup>
                            <input type="hidden" name='bot-field' />
                            <textarea name='Message' placeholder="If you have any questions, let us know here..." rows="5" />
                            {/* <input type='text' name='Page URL' value={window.location.href} readOnly /> */}
                            <button>{buttonName}</button>
                        </Form>
                    }
                </div>
            )}
        </NetlifyForm>
    );
};

contact.propTypes = {
    formName: PropTypes.node.isRequired,
    buttonName: PropTypes.node.isRequired,
};

const Form = styled.div`
    input, textarea {
        -webkit-appearance: none;
        border-radius: 0;        
        width: 100%;
        font-family: 'Lora';
        clear: both;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */
        margin-top: .5em;
        margin-bottom: .5em;
        border: 1px solid gray;
    }
    input {
        padding: 1em;
    }
    textarea {
        resize: none;
        padding: calc(1em - 2px);
    }
    input {
        
    }
    button {
        background-color: #355A2A;
        border: 0;
        color: white;
        font-family: 'Playfair Display', serif;
        padding: 1em 4em;
        transition: ease .2s;
        :hover {
            background-color: #2A4822;
            cursor: pointer;
        }
    }
`;

const InputGroup = styled.div`
    @media (min-width: 800px) {
        display: flex;
        input:nth-child(odd){
            margin-right: 1em; 
        }
    }
`;

export default contact;